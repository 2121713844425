<template>
  <div>
    <b-overlay :show="loading">
      <b-card v-if="!errorMessage">
        <h4>{{ info.name }}</h4>
        <small>วันที่สร้าง {{ gFormatDate(info.created_at, true) }}</small>
        <span class="text-muted"> | </span>
        <small>อัปเดตล่าสุด {{ gFormatDate(info.update_at, true) }}</small>
        <hr />
        <dl class="row mb-50">
          <dt class="col-sm-auto">
            <b>ผู้ส่งรีวิว</b>
          </dt>
          <dd v-if="info.owner_data" class="col-sm-auto">{{ info.owner_data.name }} ({{ info.owner_data.email }})</dd>
        </dl>

        <b-row cols="1" cols-md="2" class="mb-50">
          <b-col>
            <dl class="row mb-0">
              <dt class="col-sm-auto">
                <b>สถานะรีวิว</b>
              </dt>
              <dd v-if="info.approved_status" class="col-sm-auto">
                <b-badge :variant="statusFormatObj[info.approved_status].variant">
                  {{ statusFormatObj[info.approved_status].label }}
                </b-badge>
              </dd>
            </dl>
          </b-col>
          <b-col v-if="info.approved_status !== 'approved'">
            <dl class="row mb-0 mt-50 mt-sm-0">
              <dt class="col-sm-auto text-danger">
                <b>เหตุผลที่ไม่อนุมัติล่าสุด</b>
              </dt>
              <dd class="col-sm-auto">
                {{ info.admin_note || '-' }}
              </dd>
            </dl>
          </b-col>
        </b-row>

        <b-row cols="1" cols-sm="2">
          <b-col>
            <b>รูปราศีทั้งหมด</b>
            <p v-if="info.topic_image_list_datas" class="ml-1">{{ info.topic_image_list_datas.length }} รูป</p>
          </b-col>
          <b-col>
            <b>ขนาดรูปภาพ <small class="text-muted">( width x height )</small></b>
            <p class="ml-1">{{ dimensionImage }}</p>
          </b-col>
        </b-row>
        <div class="mb-1">
          <tab-translations-widget @onChange="onTabTranslationsChange" />
        </div>
        <b-row class="mt-1" cols="1" cols-sm="2" cols-lg="4">
          <b-col v-for="image in info.topic_image_list_datas" :key="image.id">
            <div class="">
              <box-image-preview-widget :src="image.image_thumbnail_url_preview" />
              <p class="text-center">{{ image.name_translations[keyTranslation] || 'พบข้อผิดพลาด' }}</p>
            </div>
          </b-col>
        </b-row>

        <div v-if="info.approved_status === 'checking'" class="d-flex justify-content-end">
          <b-button variant="danger" @click="$refs.refModalRejected.show()">
            ไม่อนุมัติ
          </b-button>

          <b-button class="ml-50" variant="success" @click="clickApprove">
            อนุมัติ
          </b-button>
        </div>
      </b-card>
      <b-alert v-else show variant="danger">
        <div class="alert-body">
          <feather-icon icon="InfoIcon" class="mr-50" />
          <span>{{ errorMessage }}</span>
          <b-link :to="{ name: 'zodiac-management' }">
            กดที่นี้เพื่อย้อนกลับ
          </b-link>
        </div>
      </b-alert>
    </b-overlay>

    <!-- <pre>{{ info }}</pre> -->

    <b-modal
      ref="refModalRejected"
      title="ไม่อนุมัติรูปราศี"
      hide-header-close
      cancel-title="ยกเลิก"
      ok-title="ยืนยัน"
      centered
      @ok="confirmReject"
    >
      <validation-observer ref="refFormReject">
        <b-form>
          <b-form-group label-for="note-reject" label="เหตุผลที่ไม่อนุมัติ">
            <validation-provider #default="{ errors }" name="หมายเหตุ" rules="required">
              <b-form-textarea v-model="noteReject" name="note-reject" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      info: {},
      loading: false,
      statusFormatObj: {
        '': {
          label: '',
          variant: '',
        },
        approved: {
          label: 'อนุมัติ',
          variant: 'success',
        },
        rejected: {
          label: 'ไม่อนุมัติ',
          variant: 'danger',
        },
        pending: {
          label: 'รอนุมัติ',
          variant: 'secondary',
        },
        checking: {
          label: 'กำลังตรวจสอบ',
          variant: 'warning',
        },
      },
      noteReject: '',
      errorMessage: null,
      keyTranslation: 'th',
    }
  },
  computed: {
    dimensionImage() {
      if (!this.info.topic_image_list_datas) return ''
      if (this?.info?.topic_image_list_datas.length <= 0) return ''
      const { json_data } = this.info.topic_image_list_datas[0]
      // eslint-disable-next-line camelcase
      if (json_data) {
        const { naturalHeight, naturalWidth } = JSON.parse(json_data)

        return `${naturalWidth} x ${naturalHeight}`
      }

      return 'ไม่พบข้อมูล'
    },
  },
  async created() {
    this.loading = true
    await this.getDetailsById(this.$route.params.id)
    this.loading = false
  },
  methods: {
    async getDetailsById(id) {
      if (!id) return
      const resp = await this.api.get(`api/admin/recheck-zodiac/get-zodiac/detail/${id}`)
      //   console.log('getDetailsById', resp)
      if (resp.message) {
        // console.log('error', resp.message)
        this.errorMessage = resp.message
        return
      }
      if (typeof resp === 'object') {
        this.info = resp
      }
    },
    async clickApprove() {
      const isConfirm = await this.gCheckConfirmV1('ยืนยันอนุมัติรูปราศี')
      if (!isConfirm) return

      this.loading = true
      const resp = await this.api.post('api/admin/recheck-zodiac/confirm-zodiac', {
        image_topic_zodiac_data_id: this.info.id,
        status: 'approved',
      })
      if (resp.message) {
        this.gDisplayToast('ดำเนินการไม่สำเร็จ', resp.message, 'danger')
      } else {
        this.gDisplayToast('ดำเนินการสำเร็จ')
      }
      await this.getDetailsById(this.$route.params.id)
      this.loading = false
      //   console.log('clickApprove', resp)
    },
    async confirmReject(bvEvent) {
      bvEvent.preventDefault()

      const validate = await this.$refs.refFormReject.validate()
      if (!validate) return
      this.$refs.refModalRejected.hide()

      this.loading = true
      const resp = await this.api.post('api/admin/recheck-zodiac/confirm-zodiac', {
        image_topic_zodiac_data_id: this.info.id,
        status: 'rejected',
        note: this.noteReject,
      })
      // console.log('clickReject', resp)

      if (resp.message) {
        this.gDisplayToast('ดำเนินการไม่สำเร็จ', resp.message, 'danger')
      } else {
        this.gDisplayToast('ดำเนินการสำเร็จ')
        this.noteReject = ''
      }
      await this.getDetailsById(this.$route.params.id)
      this.loading = false
    },
    onTabTranslationsChange(values) {
      this.keyTranslation = values.local_code
    },
  },
}
</script>

<style lang="scss" scoped>
.container-image-preview {
  width: 100%;
  height: 200px;
  background-color: #47526f;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 0.5rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
}
</style>
